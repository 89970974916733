import React from 'react';
import vivusLogo from '../assets/offers_logos/vivus_logo.jpg';
import ppLogo from '../assets/offers_logos/pp_logo.jpg';
import qpLogo from '../assets/offers_logos/qp_logo.jpg';
import tarjetasLogo from '../assets/offers_logos/tarjetas_logo.png';
import mrFinanLogo from '../assets/offers_logos/mrfinan_logo.jpg';
import luzoLogo from '../assets/offers_logos/luzo_logo.jpg';

interface Offer {
  logo: string;
  utp: string;
  link: string;
  isTop?: boolean; 
}

const Feed: React.FC = () => {
  const offers: Offer[] = [
    {
      logo: ppLogo,
      utp: 'Hasta <b>30.000€</b> en 15 minutos',
      link: 'http://leadsforfinance.go2cloud.org/aff_c?offer_id=1&aff_id=1177',
      isTop: true,
    },
    {
      logo: vivusLogo,
      utp: 'Recibe <b>300€</b> sin intereses',
      link: 'https://exc4finance.com/?a=411&c=2478&s1=39778807cf',
    },
    {
      logo: qpLogo,
      utp: 'Hasta <b>30.000€</b> hoy sin papeleo',
      link: 'https://leadsforfinance.go2cloud.org/aff_c?offer_id=17&aff_id=1177',
      isTop: true,
    },
    {
      logo: mrFinanLogo,
      utp: 'Solicita <b>50.000€</b> sin aval',
      link: 'http://clean.tracksacai.com/aff_c?offer_id=3272&aff_id=2181&aff_sub=39086615cf',
    },
    {
      logo: luzoLogo,
      utp: 'Consigue hasta <b>400€</b> al 0%',
      link: 'https://track.adtraction.com/t/t?a=1855209396&as=1184014562&t=2&tk=1&epi=39086615cf',
    },
    {
      logo: tarjetasLogo,
      utp: 'Hasta <b>10.000€</b> en 2 minutos',
      link: 'https://www.financeads.net/tc.php?t=18144C4682106916T&subid=39086615cf',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '16px 16px 96px 16px',
      }}
    >
      {offers.map((offer, index) => (
        <FeedItem key={index} offer={offer} />
      ))}
    </div>
  );
};

interface FeedItemProps {
  offer: Offer;
}

const FeedItem: React.FC<FeedItemProps> = ({ offer }) => {
  const { logo, utp, link, isTop } = offer;

  return (
    <a 
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ 
        textDecoration: 'none', 
        color: 'inherit', 
        position: 'relative', 
        marginTop: isTop ? '12px' : '0', 
        display: 'block', 
      }}
    >
      {isTop && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#17c964',
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '12px',
            fontWeight: 'bold',
            fontSize: '12px',
            zIndex: 1,
          }}
        >
          más popular
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: isTop ? '20px 16px 16px 16px' : '16px',
          borderRadius: '12px',
          boxShadow: isTop
            ? '0px 0px 0px 3px #45d483' 
            : '0 0px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)', 
          backgroundColor: '#fff',
          width: '100%',
          maxWidth: '608px',
          margin: '0 auto', // Центрирование для экранов более 608px
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ 
            width: '112px', 
            height: 'auto', 
            marginRight: '16px',
            objectFit: 'contain', 
          }}
        />
        <p 
          style={{ 
            margin: 0, 
            fontSize: '14px', 
            fontWeight: '550', 
            textAlign: 'center', 
            padding: '0px 16px 0px 0px', 
            color: '#3F3F46', 
          }}
          dangerouslySetInnerHTML={{ __html: utp }}
        />
        <div 
          style={{
            display: 'inline-block',
            padding: '8px 16px',
            borderRadius: '12px',
            backgroundColor: '#D5E2FA',
            color: '#2F6DE6',
            fontWeight: '550',
            fontSize: '14px',
          }}
        >
          Elegir
        </div>
      </div>
    </a>
  );
};

export default Feed;
